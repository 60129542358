import { Checkbox, IconCat, IconDog } from "ui-2";
import {
  PetChange,
  PetChangeAttributeKey,
  PetChangeType,
} from "../../../../types/pets";
import { MY_PETS } from "@constants/myPets";
import { t } from "i18next";

export interface PetAddUpdateItemProps {
  petChange: PetChange;
  selectable?: boolean;
  selectedPetIds?: string[];
  setSelectedPetIds?: (petIds: string[]) => void;
  showChanges?: boolean;
  showNewBadge?: boolean;
  onlyNewValues?: boolean;
}

export const PetAddUpdateItem = ({
  petChange,
  selectable = false,
  selectedPetIds,
  setSelectedPetIds,
  showChanges = true,
  showNewBadge = true,
  onlyNewValues = false,
}: PetAddUpdateItemProps) => {
  return (
    <div
      key={petChange.petId}
      className="flex w-full flex-col items-center justify-between gap-2 rounded-lg border border-brand-color-library-gray-300 p-4"
    >
      <div className="flex w-full items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          {selectable && (
            <Checkbox
              name={`pet-${petChange.petId}`}
              checked={selectedPetIds?.includes(petChange.petId)}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedPetIds?.([
                    ...(selectedPetIds || []),
                    petChange.petId,
                  ]);
                } else {
                  setSelectedPetIds?.(
                    selectedPetIds?.filter((id) => id !== petChange.petId) || []
                  );
                }
              }}
            />
          )}
          {defaultImage(petChange.petTypeId)}
          <div className="text-lg font-semibold text-brand-color-library-gray-900">
            {petChange.petName}
          </div>
        </div>
        {showNewBadge && petChange.type === PetChangeType.NEW && (
          <div className="whitespace-nowrap rounded-lg bg-brand-color-library-green-100 px-2 py-1 text-brand-color-library-green-800">
            {t(MY_PETS.PET_ADD_UPDATE_ITEM.NEW)}
          </div>
        )}
      </div>
      {showChanges && (
        <div className="flex w-full flex-col gap-2">
          {petChange.updates.map((update) => {
            if (onlyNewValues) {
              return (
                <div key={update.key} className="flex flex-row gap-2">
                  <div className="text-sm text-brand-color-library-gray-500">
                    {petChangeAttributeKeyToLabel[update.key]}
                  </div>
                  <div className="overflow-hidden text-ellipsis whitespace-nowrap text-sm text-brand-color-library-gray-900">
                    {update.newValue}
                  </div>
                </div>
              );
            } else {
              return (
                <div key={update.key} className="flex flex-col gap-1">
                  <div className="text-sm text-brand-color-library-gray-500">
                    {petChangeAttributeKeyToLabel[update.key]}
                  </div>
                  <div className="flex items-center gap-1">
                    <span className="text-brand-color-library-gray-400 max-w-[50%] overflow-hidden text-ellipsis whitespace-nowrap text-sm">
                      {update.oldValue}
                    </span>
                    <span className="text-sm text-brand-color-library-gray-600">
                      →
                    </span>
                    <span className="text-brand-color-library-gray-700 max-w-[50%] overflow-hidden text-ellipsis whitespace-nowrap text-sm font-semibold">
                      {update.newValue}
                    </span>
                  </div>
                </div>
              );
            }
          })}
        </div>
      )}
    </div>
  );
};

const defaultImage = (petTypeId: number) => {
  return (
    <div className="flex h-9 w-9 items-center justify-center rounded-full bg-[#E6E6E6]">
      {petTypeId === 1 ? IconDog : IconCat}
    </div>
  );
};

const petChangeAttributeKeyToLabel: Record<PetChangeAttributeKey, string> = {
  [PetChangeAttributeKey.PET_NICK_NAME]: t(MY_PETS.PET_ADD_UPDATE_ITEM.NICKNAME),
  [PetChangeAttributeKey.PET_BIRTHDAY]: t(MY_PETS.PET_ADD_UPDATE_ITEM.BIRTHDAY),
  [PetChangeAttributeKey.PET_ADOPT_DATE]: t(MY_PETS.PET_ADD_UPDATE_ITEM.ADOPTION_DATE),
  [PetChangeAttributeKey.PET_SIZE]: t(MY_PETS.PET_ADD_UPDATE_ITEM.SIZE),
  [PetChangeAttributeKey.PET_GENDER]: t(MY_PETS.PET_ADD_UPDATE_ITEM.GENDER),
  [PetChangeAttributeKey.PET_BREED]: t(MY_PETS.PET_ADD_UPDATE_ITEM.BREED),
  [PetChangeAttributeKey.PET_FOOD_TYPE]: t(MY_PETS.PET_ADD_UPDATE_ITEM.FOOD),
  [PetChangeAttributeKey.PET_LIFE_STAGE]: t(MY_PETS.PET_ADD_UPDATE_ITEM.LIFE_STAGE),
  [PetChangeAttributeKey.PET_VET_NAME]: t(MY_PETS.PET_ADD_UPDATE_ITEM.VETERINARIAN),
};
